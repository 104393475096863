import { quizPb } from "@augmedi/proto-gen";
import {
  Button,
  Group,
  Stack,
  Title,
  TypographyStylesProvider,
} from "@mantine/core";
import { MediaItemAsset } from "./MediaItemAsset";

interface Props {
  approachStep: quizPb.FrozenApproach_Step;
  currentStep: number;
  numSteps: number;
  setCurrentStep: (step: number) => void;
  labelSection?: JSX.Element;
}

export const ApproachViewInfoContent = ({
  approachStep,
  currentStep,
  numSteps,
  setCurrentStep,
  labelSection,
}: Props) => {
  return (
    <Stack>
      <Stack>
        <Title order={3}>{approachStep.title}</Title>
        <Stack gap="sm">
          {approachStep.infoSections.map((section) =>
            section.content.case === "text" ? (
              <TypographyStylesProvider key={section.id}>
                <div
                  dangerouslySetInnerHTML={{ __html: section.content.value }}
                  style={{ whiteSpace: "pre-wrap" }}
                />
              </TypographyStylesProvider>
            ) : section.content.value ? (
              <MediaItemAsset mediaItemId={section.content.value} />
            ) : null,
          )}
        </Stack>
      </Stack>
      {labelSection}
      <Stack align="flex-start" gap="xl">
        <Group>
          <Button
            disabled={currentStep === 0}
            onClick={() => {
              if (currentStep > 0) {
                setCurrentStep(currentStep - 1);
              }
            }}
          >
            Previous step
          </Button>
          <Button
            disabled={currentStep === numSteps - 1}
            onClick={() => {
              if (currentStep < numSteps - 1) {
                setCurrentStep(currentStep + 1);
              }
            }}
          >
            Next step
          </Button>
        </Group>
      </Stack>
    </Stack>
  );
};
