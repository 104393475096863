/* eslint @typescript-eslint/no-unused-vars: off */
import { quizConnectQuery, quizPb } from "@augmedi/proto-gen";
import {
  createConnectQueryKey,
  useMutation,
  useSuspenseQuery,
} from "@connectrpc/connect-query";
import {
  Button,
  Container,
  Group,
  Modal,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Link, useLocation } from "wouter";

export const ApproachListPage = () => {
  const [_location, navigate] = useLocation();
  const queryClient = useQueryClient();

  const listApproachesQuery = useSuspenseQuery(quizConnectQuery.listApproaches);

  const [isModalOpen, setModalOpen] = useState(false);
  const [newApproachName, setNewApproachName] = useState("");
  const createApproachMutation = useMutation(quizConnectQuery.createApproach, {
    onSuccess: async (res) => {
      await queryClient.invalidateQueries({
        queryKey: createConnectQueryKey(quizConnectQuery.listApproaches),
      });
      setModalOpen(false);
      navigate(`/approaches/${res.id}/draft`);
    },
  });

  const permissionsQuery = useSuspenseQuery(quizConnectQuery.whoAmI);
  const permissions = permissionsQuery.data?.permissions ?? [];
  const canEdit = permissions.includes(quizPb.Permission.EDIT_APPROACHES);

  return (
    <>
      <Container py="md">
        <Stack>
          <Group justify="space-between">
            <Title order={1}>Approaches</Title>
            <Button onClick={() => setModalOpen(true)}>New approach</Button>
          </Group>

          {(listApproachesQuery.data?.approaches ?? [])
            .filter((approach) => approach.latestFrozenApproachId || canEdit)
            .map((approach) => {
              const displayName = approach.name.trim() || "(untitled approach)";
              const isViewable = !!approach.latestFrozenApproachId;
              return (
                <div key={approach.id}>
                  <Text>
                    {isViewable ? (
                      <Link to={`/approaches/${approach.id}/view`}>
                        {displayName}
                      </Link>
                    ) : (
                      displayName
                    )}
                    {canEdit && (
                      <Link
                        to={`/approaches/${approach.id}/draft`}
                        style={{ marginLeft: 5 }}
                      >
                        (edit)
                      </Link>
                    )}
                  </Text>
                </div>
              );
            })}
        </Stack>
      </Container>

      <Modal
        opened={isModalOpen}
        onClose={() => setModalOpen(false)}
        title="New approach"
      >
        <Stack>
          <TextInput
            label="Name"
            description="Can be changed later"
            placeholder="Name"
            value={newApproachName}
            onChange={(ev) => setNewApproachName(ev.target.value)}
          />
          <Button
            onClick={() =>
              createApproachMutation.mutate({
                name: newApproachName,
              })
            }
            disabled={createApproachMutation.isPending}
          >
            Create approach
          </Button>
        </Stack>
      </Modal>
    </>
  );
};
