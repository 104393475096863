import { Container, Stack, Title } from "@mantine/core";

interface Props {
  infoContent?: JSX.Element;
  title: string;
}

export const ApproachStepLayoutWithoutModel = ({
  infoContent,
  title,
}: Props) => {
  return (
    <Container size="sm" py="lg">
      <Stack gap="lg">
        <Title order={1}>{title}</Title>
        {infoContent}
      </Stack>
    </Container>
  );
};
