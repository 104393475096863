import { Box, Flex, Stack, Title } from "@mantine/core";
import classNames from "classnames";
import { useIsMobileWithCanvas } from "../logic/app-layout";
import * as classes from "./QuizLayout.module.css";

interface Props {
  canvasContent?: JSX.Element;
  infoContent?: JSX.Element;
  canvasExtrasSmall?: JSX.Element;
  title: string;
}

export const ApproachStepLayoutWithModel = ({
  canvasContent,
  infoContent,
  canvasExtrasSmall,
  title,
}: Props) => {
  const isMobileWithCanvas = useIsMobileWithCanvas();

  return (
    <Flex
      className={classNames({
        [classes.outerWrapper]: true,
        [classes.desktopOuterWrapper]: !isMobileWithCanvas,
      })}
    >
      {/* Canvas */}

      <div className={classes.canvasWrapper}>
        {canvasContent}
        <div className={classes.canvasExtrasWrapper}>{canvasExtrasSmall}</div>
      </div>

      {/* Content */}
      <Box
        className={classNames({
          [classes.contentWrapper]: true,
        })}
      >
        {/* Title */}
        <Title className={classes.title} order={4}>
          {title}
        </Title>

        {/* Inner Content */}

        <Box
          className={classes.innerContentWrapper}
          p={isMobileWithCanvas ? "sm" : "md"}
        >
          <Stack gap="sm">{infoContent}</Stack>
        </Box>
      </Box>
    </Flex>
  );
};
