import { quizPb } from "@augmedi/proto-gen";
import { proto3 } from "@bufbuild/protobuf";
import { Container, Group, Paper, Stack, Title } from "@mantine/core";
import { Link } from "wouter";
import { getSubscriptionNeed } from "../logic/subscribe";
import * as classes from "./QuizChapter.module.css";
import { TrialInfoBox } from "./TrialInfoBox";
import { bodyPartToString } from "./utils";

interface Props {
  whoAmIResponse: quizPb.WhoAmIResponse;
}

export const QuizBodyPartListPage = ({ whoAmIResponse }: Props) => {
  const subscriptionNeed = getSubscriptionNeed(whoAmIResponse);

  const bodyParts = proto3
    .getEnumType(quizPb.BodyPart)
    .values.map((v) => v.no as quizPb.BodyPart);

  return (
    <Container py="md">
      <Stack>
        <Title>Regions</Title>
        {bodyParts.map((bodyPart) => (
          <Link
            key={bodyPart}
            to={`/quiz/regions/${bodyPartToString[bodyPart]}`}
            className={classes.chapterCard}
          >
            <Paper p="md">
              <Group
                gap="xs"
                justify="space-between"
                align="center"
                wrap="nowrap"
              >
                <Title order={4}>{bodyPartToString[bodyPart]}</Title>{" "}
              </Group>
            </Paper>
          </Link>
        ))}
        {subscriptionNeed && (
          <TrialInfoBox subscriptionNeed={subscriptionNeed} />
        )}
      </Stack>
    </Container>
  );
};
