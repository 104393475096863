/* eslint @typescript-eslint/no-unused-vars: off */
import { useAuth0 } from "@auth0/auth0-react";
import { TransportProvider } from "@connectrpc/connect-query";
import { createConnectTransport } from "@connectrpc/connect-web";
import { AppShell, MantineProvider } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Notifications } from "@mantine/notifications";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Suspense, useEffect, useMemo, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "wouter";
import {
  AppLayout,
  AppLayoutContext,
  type AppLayoutContextValue,
} from "../logic/app-layout.js";
import { AnnotatorPage } from "./AnnotatorPage.js";
import { AppHeader } from "./AppHeader.js";
import { AppNavbar } from "./AppNavbar.js";
import { ApproachDraftPage } from "./ApproachDraftPage.js";
import { ApproachFrozenPage } from "./ApproachFrozenPage.js";
import { ApproachListPage } from "./ApproachListPage.js";
import { ApproachStepDraftPage } from "./ApproachStepDraftPage.js";
import { ApproachView } from "./ApproachView.js";
import { AtlasPage } from "./AtlasPage.js";
import { AuthQrPage } from "./AuthQrPage.js";
import { CameraAngleTest } from "./CameraAngleTest.js";
import { FallbackWithoutReset } from "./FallbackWithoutReset.js";
import { GlobalCanvas } from "./GlobalCanvas.js";
import { LabelEditorPage } from "./LabelEditorPage.js";
import { LargeLoader } from "./LargeLoader.js";
import { LoginPage } from "./LoginPage.js";
import { LoginRedirect } from "./LoginRedirect.js";
import { MeshSelectorPage } from "./MeshSelectorPage/MeshSelectorPage.js";
import { ModelListPage } from "./ModelListPage.js";
import { ModelPage } from "./ModelPage.js";
import { NotFoundPage } from "./NotFoundPage.js";
import { OldLoginPage } from "./OldLoginPage.js";
import { QuestionPage } from "./QuestionPage.js";
import { QuestionsPage } from "./QuestionsPage.js";
import { QuizBodyPartPage } from "./QuizBodyPartPage.js";
import { QuizChapterPage } from "./QuizChapterPage.js";
import { QuizEntryPage } from "./QuizEntryPage.js";
import { QuizRunPage } from "./QuizRunPage.js";
import { RouteProvider } from "./RouteProvider.js";
import { SignupPage } from "./SignupPage.js";
import { StatsPage } from "./Stats/StatsPage.js";
import { ThankYouPage } from "./ThankYouPage.js";
import { TreeAnatomyLabellingPage } from "./TreeAnatomyLabelling/TreeAnatomyLabellingPage.jsx";
import { TreeAnatomyLandingPage } from "./TreeAnatomyLabelling/TreeAnatomyLandingPage.js";
import { UserPage } from "./UserPage.js";
import { UserSettingsPage } from "./UserSettings.js";
import { UsersPage } from "./UsersPage.js";

const queryClient = new QueryClient();

const transport = createConnectTransport({
  baseUrl: "/api",
});

export const App = () => {
  const { isLoading, error } = useAuth0();

  const [isMobileWithCanvas, setIsMobileWithCanvas] = useState(false);
  const [layout, setLayout] = useState<AppLayout>(AppLayout.Default);
  const appLayoutContextValue = useMemo(
    (): AppLayoutContextValue => ({ layout, setLayout }),
    [layout, setLayout],
  );

  const [navbarOpened, { toggle: toggleNavbar, close: closeNavbar }] =
    useDisclosure();

  const [location, _setLocation] = useLocation();
  useEffect(() => {
    closeNavbar();
  }, [location, closeNavbar]);

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <TransportProvider transport={transport}>
      <MantineProvider>
        <QueryClientProvider client={queryClient}>
          <RouteProvider>
            <AppLayoutContext.Provider value={appLayoutContextValue}>
              <Notifications limit={1} />
              <AppShell
                header={{ height: isMobileWithCanvas ? 0 : 60 }}
                bg="gray.0"
                navbar={{
                  width: 300,
                  breakpoint: "md",
                  collapsed: { desktop: true, mobile: !navbarOpened },
                }}
              >
                <AppHeader
                  burgerOpened={navbarOpened}
                  onBurgerClick={toggleNavbar}
                  onNavLinkClick={closeNavbar}
                  onHeaderChange={setIsMobileWithCanvas}
                />
                <AppNavbar
                  onNavLinkClick={closeNavbar} // HACK This handles the case when the user clicks the page that they're already on
                />
                <AppShell.Main
                  style={
                    layout === AppLayout.FullscreenWithHeader
                      ? { height: "1px", overflow: "hidden" }
                      : undefined
                  }
                >
                  <Sentry.ErrorBoundary fallback={FallbackWithoutReset}>
                    <GlobalCanvas />
                    <Suspense fallback={<LargeLoader />}>
                      <Switch>
                        <Route path="/models" component={ModelListPage} />
                        <Route
                          path="/models/:modelId/labelEditor"
                          component={LabelEditorPage}
                        />
                        <Route
                          path="/models/:modelId/meshSelector"
                          component={MeshSelectorPage}
                        />
                        <Route
                          path="/models/:modelId/:modelPageTab"
                          component={ModelPage}
                        />
                        <Route
                          path="/approaches"
                          component={ApproachListPage}
                        />
                        <Route
                          path="/approaches/:approachId/view"
                          component={ApproachView}
                        />
                        <Route
                          path="/approaches/:approachId/draft"
                          component={ApproachDraftPage}
                        />
                        <Route
                          path="/approaches/:approachId/draft/steps/:approachStepId"
                          component={ApproachStepDraftPage}
                        />
                        <Route
                          path="/approaches/:approachId/frozen"
                          component={ApproachFrozenPage}
                        />
                        <Route
                          path="/quiz/chapters"
                          component={QuizEntryPage}
                        />
                        <Route
                          path="/quiz/chapters/:chapterId"
                          component={QuizChapterPage}
                        />
                        <Route
                          path="/quiz/regions/:regionId"
                          component={QuizBodyPartPage}
                        />
                        <Route path="/quiz/run" component={QuizRunPage} />
                        <Route path="/questions" component={QuestionsPage} />
                        <Route
                          path="/question/:questionId"
                          component={QuestionPage}
                        />
                        <Route path="/atlas/" component={AtlasPage} />
                        <Route
                          path="/atlas/structures/:structureId"
                          component={AtlasPage}
                        />
                        <Route path="/annotator" component={AnnotatorPage} />
                        <Route
                          path="/label-tree-anatomy"
                          component={TreeAnatomyLandingPage}
                        />
                        <Route
                          path="/label-tree-anatomy/:projectName"
                          component={TreeAnatomyLabellingPage}
                        />
                        <Route path="/auth-qr" component={AuthQrPage} />
                        <Route path="/camera" component={CameraAngleTest} />
                        <Route path="/stats" component={StatsPage} />
                        <Route path="/login" component={LoginPage} />
                        <Route path="/old-login" component={OldLoginPage} />
                        <Route path="/signup" component={SignupPage} />
                        <Route path="/sign-up" component={SignupPage} />
                        <Route path="/thank-you" component={ThankYouPage} />
                        <Route path="/users" component={UsersPage} />
                        <Route path="/users/:userId" component={UserPage} />
                        <Route
                          path="/user-settings"
                          component={UserSettingsPage}
                        />
                        <Route path="/">
                          <Redirect to="/quiz/chapters" />
                        </Route>
                        <Route component={NotFoundPage} />
                      </Switch>
                    </Suspense>
                  </Sentry.ErrorBoundary>
                  <LoginRedirect />
                </AppShell.Main>
              </AppShell>
            </AppLayoutContext.Provider>
          </RouteProvider>
        </QueryClientProvider>
      </MantineProvider>
    </TransportProvider>
  );
};
