import { quizConnectQuery, quizPb } from "@augmedi/proto-gen";
import { useSuspenseQuery } from "@connectrpc/connect-query";
import { Image } from "@mantine/core";
import Zoom from "react-medium-image-zoom";

interface Props {
  mediaItemId: string;
}

export const MediaItemAsset = ({ mediaItemId }: Props) => {
  const mediaItem = useSuspenseQuery(quizConnectQuery.getMediaItemAsset, {
    id: mediaItemId,
  });

  if (mediaItem.data?.type === quizPb.MediaItemType.IMAGE) {
    return (
      <Zoom>
        <Image
          radius="md"
          src={mediaItem.data.downloadUrl}
          alt={mediaItemId}
          h="auto"
          w="100%"
          fit="contain"
        />
      </Zoom>
    );
  } else if (mediaItem.data?.type === quizPb.MediaItemType.VIDEO) {
    return (
      <video controls playsInline width="100%" height="100%">
        <source src={mediaItem.data.downloadUrl} type="video/mp4" />
      </video>
    );
  }
  return null;
};
