import { useMantineTheme } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { createContext, useContext, useEffect, useMemo } from "react";
import { useLocation } from "wouter";

export enum AppLayout {
  Default = "Default",
  FullscreenWithHeader = "FullscreenWithHeader",
}

export interface AppLayoutContextValue {
  layout: AppLayout;
  setLayout: (layout: AppLayout) => void;
}

export const AppLayoutContext = createContext<AppLayoutContextValue>({
  layout: AppLayout.Default,
  setLayout: () => {
    console.warn(
      "setLayout called outside of an AppLayoutContext. Your component is probably not nested under a provider.",
    );
  },
});

export function useAppLayout(layout: AppLayout) {
  const { setLayout } = useContext(AppLayoutContext);

  useEffect(() => {
    setLayout(layout);
    return () => setLayout(AppLayout.Default);
  }, [layout, setLayout]);
}

export function useIsMobile() {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);
  return isMobile;
}

const viewPathsWithCanvas = ["/quiz/run"];
export function useIsMobileWithCanvas() {
  const location = useLocation();
  const isMobile = useIsMobile();
  const isMobileWithCanvas = useMemo(
    () =>
      isMobile &&
      viewPathsWithCanvas.some((viewPath) => location.includes(viewPath)),
    [isMobile, location],
  );
  return isMobileWithCanvas;
}
