import { quizConnectQuery, quizPb } from "@augmedi/proto-gen";
import { useSuspenseQuery } from "@connectrpc/connect-query";
import { Checkbox, Group, Stack, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { AppLayout, useAppLayout } from "../logic/app-layout";
import {
  splitHighlightsIntoChannels,
  type HighlightedId,
} from "../logic/highlights";
import { ApproachStepLayoutWithModel } from "./ApproachStepLayoutWithModel";
import { ApproachViewInfoContent } from "./ApproachViewInfoContent";
import { FrozenModelPreview } from "./FrozenModelPreview";
import { LogoImage } from "./LogoImage";
import ModelViewerUi from "./ModelViewerUi";
import { OurCanvas } from "./OurCanvas";
import type { SharedModelPreviewStuffRef } from "./SharedModelPreviewStuff";

interface selectableLabels {
  id: string;
  selected: boolean;
}

interface Props {
  title: string;
  approachStep: quizPb.FrozenApproach_Step;
  currentStep: number;
  numSteps: number;
  permissions: quizPb.Permission[];
  isMobileWithCanvas: boolean | undefined;
  sharedStuffRef: React.MutableRefObject<SharedModelPreviewStuffRef | null>;
  setCurrentStep: (step: number) => void;
}

function highlightedIds(
  selectedItemIds: selectableLabels[],
  structureLablesIds: string[],
): HighlightedId[] {
  const highlightedIdsFromSelected = new Set(
    structureLablesIds.filter((id) =>
      selectedItemIds.some((item) => item.id === id && item.selected),
    ),
  );
  return [...highlightedIdsFromSelected].map((id) => ({
    id,
    color: "#0091e399",
    pulse: true,
  }));
}

export const ApproachViewPageWithModel = ({ title, ...restProps }: Props) => {
  const { approachStep, currentStep, sharedStuffRef } = restProps;

  useAppLayout(AppLayout.FullscreenWithHeader);

  const listModelMeshes = useSuspenseQuery(quizConnectQuery.getFrozenModel, {
    id: approachStep.frozenModelId,
  });

  const visibleMeshIds = listModelMeshes.data.manifest?.meshes.map(
    (mesh) => mesh.id,
  );

  const structureLablesIds =
    listModelMeshes.data.manifest?.labels.map((label) => label.id) ?? [];

  const visibleLables =
    listModelMeshes.data.manifest?.labels.filter(
      (label) => !label.isWholeMeshLabel,
    ) ?? [];

  const [selectedItemIds, setSelectedItemIds] = useState<selectableLabels[]>(
    visibleLables.map((label) => ({ id: label.id, selected: false })),
  );

  const selectedIds = highlightedIds(selectedItemIds, structureLablesIds);

  const { visibleLabelIds, settingsByChannel } =
    splitHighlightsIntoChannels(selectedIds);

  useEffect(() => {
    setSelectedItemIds(
      visibleLables.map((label) => ({ id: label.id, selected: false })),
    );
  }, [currentStep]);

  const canvasContent = (
    <ModelViewerUi onResetCamera={() => sharedStuffRef.current?.resetCamera()}>
      <OurCanvas>
        <FrozenModelPreview
          ref={sharedStuffRef}
          frozenModelId={approachStep.frozenModelId}
          backgroundColor={0xffffff}
          visibleMeshIds={visibleMeshIds}
          visibleLabelIdsPerChannel={visibleLabelIds}
          settingsByChannel={settingsByChannel}
        />
      </OurCanvas>
    </ModelViewerUi>
  );

  const canvasExtrasSmall = (
    <Group
      style={{
        position: "absolute",
        left: "1rem",
        bottom: "1rem",
        opacity: 0.5,
      }}
    >
      <LogoImage />
    </Group>
  );

  const labelSection = (
    <Stack gap="xs">
      {visibleLables.map((item) => (
        <Checkbox
          key={item.id}
          label={<Text size="sm">{item.name ?? "Unknown item"}</Text>}
          style={{ "--mantine-cursor-type": "pointer" }}
          onClick={() =>
            setSelectedItemIds(
              selectedItemIds.map((label) => {
                if (label.id === item.id) {
                  return { ...label, selected: !label.selected };
                }
                return label;
              }),
            )
          }
          readOnly
        />
      ))}
    </Stack>
  );

  return (
    <ApproachStepLayoutWithModel
      title={title}
      canvasContent={canvasContent}
      infoContent={
        <ApproachViewInfoContent {...restProps} labelSection={labelSection} />
      }
      canvasExtrasSmall={canvasExtrasSmall}
    />
  );
};
