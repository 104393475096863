import { quizConnectQuery } from "@augmedi/proto-gen";
import { useSuspenseQuery } from "@connectrpc/connect-query";
import { useRef, useState } from "react";
import { useParams } from "wouter";
import { useIsMobileWithCanvas } from "../logic/app-layout";
import { ApproachViewPageWithModel } from "./ApproachViewPageWithModel";
import { ApproachViewPageWithoutModel } from "./ApproachViewPageWithoutModel";
import type { SharedModelPreviewStuffRef } from "./SharedModelPreviewStuff";

export const ApproachView = () => {
  const { approachId } = useParams<{ approachId: string }>();
  const isMobileWithCanvas = useIsMobileWithCanvas();
  const [currentStep, setCurrentStep] = useState(0);

  const permissionsQuery = useSuspenseQuery(quizConnectQuery.whoAmI);
  const permissions = permissionsQuery.data?.permissions ?? [];

  const getApproachQuery = useSuspenseQuery(quizConnectQuery.getApproach, {
    id: approachId,
  });
  if (!getApproachQuery.data?.latestFrozenApproachId) {
    throw new Error("Approach has no frozen version");
  }
  const getFrozenApproachQuery = useSuspenseQuery(
    quizConnectQuery.getFrozenApproach,
    {
      id: getApproachQuery.data.latestFrozenApproachId,
    },
  );

  const frozenApproach = getFrozenApproachQuery.data;
  if (!frozenApproach.manifest) {
    throw new Error("Frozen approach has no manifest");
  }

  const approachSteps = frozenApproach.manifest.steps;
  const numSteps = approachSteps.length;
  const sharedStuffRef = useRef<SharedModelPreviewStuffRef>(null);

  if (approachSteps[currentStep].frozenModelId === "") {
    return (
      <ApproachViewPageWithoutModel
        title={frozenApproach.manifest.name}
        approachStep={approachSteps[currentStep]}
        currentStep={currentStep}
        numSteps={numSteps}
        permissions={permissions}
        setCurrentStep={setCurrentStep}
      />
    );
  } else {
    return (
      <ApproachViewPageWithModel
        title={frozenApproach.manifest.name}
        isMobileWithCanvas={isMobileWithCanvas}
        approachStep={approachSteps[currentStep]}
        currentStep={currentStep}
        numSteps={numSteps}
        permissions={permissions}
        setCurrentStep={setCurrentStep}
        sharedStuffRef={sharedStuffRef}
      />
    );
  }
};
