import { quizPb } from "@augmedi/proto-gen";
import { ApproachStepLayoutWithoutModel } from "./ApproachStepLayoutWithoutModel";
import { ApproachViewInfoContent } from "./ApproachViewInfoContent";

interface Props {
  title: string;
  approachStep: quizPb.FrozenApproach_Step;
  permissions: quizPb.Permission[];
  currentStep: number;
  numSteps: number;
  setCurrentStep: (step: number) => void;
}

export const ApproachViewPageWithoutModel = ({
  title,
  ...restProps
}: Props) => {
  return (
    <ApproachStepLayoutWithoutModel
      title={title}
      infoContent={<ApproachViewInfoContent {...restProps} />}
    />
  );
};
