import { quizConnectQuery, quizPb } from "@augmedi/proto-gen";
import { useSuspenseQuery } from "@connectrpc/connect-query";
import { Alert, Button, Container, Group } from "@mantine/core";
import { useState } from "react";
import { QuizBodyPartListPage } from "./QuizBodyPartListPage";
import { QuizChapterListPage } from "./QuizChapterListPage";

enum LearningMode {
  ByChapter = "ByChapter",
  ByRegion = "ByRegion",
}

export const QuizEntryPage = () => {
  const [learningMode, setLearningMode] = useState<LearningMode>(
    LearningMode.ByChapter,
  );
  const whoAmIQuery = useSuspenseQuery(quizConnectQuery.whoAmI);

  if (
    !whoAmIQuery.data.permissions.includes(quizPb.Permission.USE_ANATOMY_QUIZ)
  ) {
    return (
      <Container py="md" maw="400px">
        <Alert variant="light" color="yellow" radius="md" title="No access">
          {
            "You don't have permission to access TeachAnatomy. Choose another page from the main menu or contact us for support."
          }
        </Alert>
      </Container>
    );
  }

  return (
    <Container py="md">
      <Group gap="xs" justify="center" wrap="nowrap">
        <Button
          variant={
            learningMode === LearningMode.ByChapter ? "filled" : "outline"
          }
          onClick={() => setLearningMode(LearningMode.ByChapter)}
        >
          Learn by Chapter
        </Button>
        <Button
          variant={
            learningMode === LearningMode.ByRegion ? "filled" : "outline"
          }
          onClick={() => setLearningMode(LearningMode.ByRegion)}
        >
          Learn by Region
        </Button>
      </Group>
      {learningMode === LearningMode.ByChapter ? (
        <QuizChapterListPage whoAmIResponse={whoAmIQuery.data} />
      ) : (
        <QuizBodyPartListPage whoAmIResponse={whoAmIQuery.data} />
      )}
    </Container>
  );
};
