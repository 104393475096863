import { quizPb } from "@augmedi/proto-gen";
import { Button, Menu, Text } from "@mantine/core";
import { Link } from "wouter";
import * as classes from "./AppNavLinks.module.css";
import { DividerWithContent } from "./DividerWithContent";
import { IconChevronForDropdown } from "./IconChevronForDropdown";

export enum NavLinkAdminStyle {
  Dropdown = "Dropdown",
  Separator = "Separator",
}

interface Props {
  permissions: quizPb.Permission[];
  onNavLinkClick: () => void;
  adminStyle: NavLinkAdminStyle;
  extraNonAdminContent?: React.ReactNode;
}

interface LinkConfig {
  text: string;
  to: string;
  requires: quizPb.Permission;
}

const nonAdminLinks: LinkConfig[] = [
  {
    text: "Learn",
    to: "/quiz/chapters",
    requires: quizPb.Permission.USE_ANATOMY_QUIZ,
  },
  {
    text: "Structures",
    to: "/atlas",
    requires: quizPb.Permission.READ_ATLAS_AUGMEDI_INTERNAL,
  },
  {
    text: "Progress",
    to: "/stats",
    requires: quizPb.Permission.USE_ANATOMY_QUIZ,
  },
  {
    text: "Approaches",
    to: "/approaches",
    requires: quizPb.Permission.LIST_APPROACHES,
  },
  { text: "AR/VR login", to: "/auth-qr", requires: quizPb.Permission.QR_CODE },
];

const adminLinks: LinkConfig[] = [
  { text: "Models", to: "/models", requires: quizPb.Permission.EDIT_MODELS },
  {
    text: "Questions",
    to: "/questions",
    requires: quizPb.Permission.SEARCH_QUIZ_ITEMS,
  },
  {
    text: "Label book pages",
    to: "/annotator",
    requires: quizPb.Permission.ANNOTATE,
  },
  {
    text: "Label tree anatomy",
    to: "/label-tree-anatomy",
    requires: quizPb.Permission.ANNOTATE,
  },
  { text: "Users", to: "/users", requires: quizPb.Permission.READ_USERS },
];

export const AppNavLinks = ({
  permissions,
  onNavLinkClick,
  adminStyle,
  extraNonAdminContent,
}: Props) => {
  const filteredNonAdminLinks = nonAdminLinks.filter((link) =>
    permissions.includes(link.requires),
  );
  const filteredAdminLinks = adminLinks.filter((link) =>
    permissions.includes(link.requires),
  );

  return (
    <>
      {filteredNonAdminLinks.map((link) => (
        <Link
          key={link.to}
          className={classes.menuButton}
          to={link.to}
          onClick={onNavLinkClick}
        >
          {link.text}
        </Link>
      ))}
      {extraNonAdminContent}
      {adminStyle === NavLinkAdminStyle.Separator && (
        <DividerWithContent>
          <Text c="dimmed">Admin pages</Text>
        </DividerWithContent>
      )}
      {adminStyle === NavLinkAdminStyle.Separator &&
        filteredAdminLinks.map((link) => (
          <Link
            key={link.to}
            className={classes.menuButton}
            to={link.to}
            onClick={onNavLinkClick}
          >
            {link.text}
          </Link>
        ))}
      {adminStyle === NavLinkAdminStyle.Dropdown && (
        <Menu>
          <Menu.Target>
            <Button
              color="#495057"
              variant="subtle"
              rightSection={<IconChevronForDropdown />}
              className={classes.menuButton}
              style={{ paddingRight: 6 }}
            >
              Admin
            </Button>
          </Menu.Target>
          <Menu.Dropdown>
            {filteredAdminLinks.map((link) => (
              <Menu.Item
                key={link.to}
                component={Link}
                to={link.to}
                onClick={onNavLinkClick}
              >
                {link.text}
              </Menu.Item>
            ))}
          </Menu.Dropdown>
        </Menu>
      )}
    </>
  );
};
